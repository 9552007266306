import fetcher from '@lib/fetcher'
import { getAdCookie, getQuery } from '@lib/utils/cookies'
import Cookies from 'js-cookie'

const referralApi = {
  login(data, locale, headers) {
    return fetcher({
      locale,
      url: '/api/multipass/security/v2/pub/passport/login',
      method: 'POST',
      body: data,
      headers: { ...headers },
    })
  },
  // 生成短链数据
  createReferralUrl(data, locale, headers) {
    return fetcher({
      locale,
      url: '/api/multipass/security/v1/shopping/web/s/create',
      method: 'POST',
      body: data,
      headers: { ...headers },
    })
  },
  // 获取用户referral信息
  getReferralUser(locale, headers) {
    return fetcher({
      locale,
      url: '/api/multipass/security/v1/shopping/pub/referral/shopping/get_referral_user',
      method: 'POST',
      // body: data,
      headers: { ...headers },
    })
  },
  // 获取提现或兑换产品订单
  getWithdrawList(locale, headers) {
    return fetcher({
      locale,
      url: '/api/multipass/security/v1/shopping/pub/referral/shopping/get_withdraw_list',
      method: 'POST',
      headers: { ...headers },
    })
  },
  // 获取当前用户有多少个满足的referral订单
  getReferralCount(locale, headers) {
    return fetcher({
      locale,
      url: '/api/multipass/security/v1/shopping/pub/referral/shopping/get_referral_count',
      method: 'GET',
      headers: { ...headers },
    })
  },
  // 获取用户的referral订单列表
  getReferralOrders(data, locale, headers) {
    return fetcher({
      locale,
      url: '/api/multipass/security/v1/shopping/pub/referral/shopping/get_referral_orders',
      method: 'POST',
      body: data,
      headers: { ...headers },
    })
  },
  // 提现
  getApplyWithdraw(data, locale, headers) {
    return fetcher({
      locale,
      url: '/api/multipass/security/v1/shopping/pub/referral/shopping/get_apply_withdraw',
      method: 'POST',
      body: data,
      headers: { ...headers },
    })
  },
  getExchangeProduct(data, locale, headers) {
    return fetcher({
      locale,
      url: '/api/multipass/security/v1/shopping/pub/referral/shopping/get_exchange_product',
      method: 'POST',
      body: data,
      headers: { ...headers },
    })
  },
  referralShare(data, locale, headers) {
    return fetcher({
      action: 'collect',
      needRecaptcha: true,
      locale,
      url: '/api/multipass/rainbowbridge/collect/share',
      method: 'POST',
      body: data,
      headers: { ...headers },
    })
  },
  getUDCGroups(locale, useId, headers = {}) {
    return fetcher({
      locale,
      url: `/api/multipass/rainbowbridge/referrals/users/${useId}/udc-groups`,
      method: 'GET',
      headers: { ...headers },
    })
  },
  uploadCommerceEvents(data, locale, headers) {
    const {
      extraData = '',
      sub,
      menu,
      type,
      token,
      source,
      ...otherData
    } = data
    const { ref = '', inviter_code } = getAdCookie()
    const query = getQuery()
    const userAgent = window && window.navigator.userAgent
    let pageRef = ' '
    if (location.href) {
      let host =
        location.href.indexOf('?') != -1
          ? location.href.split('?')[0]
          : location.href
      pageRef = host.indexOf('/pages/') != -1 ? host.split('/pages/')[1] : ''
    }
    const postdata = {
      events: [
        {
          ab_code: locale.toUpperCase(),
          business: 'Referral',
          event_local_time: new Date(),
          event_time: Date.parse(new Date()),
          event_type: {
            page: 10001,
            click: 10100,
          }[data?.type], // 进入页面(或回到页面) 10002-页面加载完成 10003-退出页面 10100-点击事件
          extra1: query?.ref
            ? ref + extraData
              ? ref + extraData + `&utm_ref=${query?.ref}`
              : `utm_ref=${query?.ref}`
            : ref + extraData, // 保留字段1
          extra2: `{"userAgent": "${userAgent}"}`, // 保留字段2
          log_version: 100, // 日志版本号，100-表示1.00版本
          module: data?.menu, // 所属模块，如: ShopHomePage
          sub_module: data?.sub, // 所属子模块，如: ReferalInviteeHomePage
          ...(Object.keys(otherData).length && {
            click_data: { ...otherData, label: pageRef },
          }), // 点击数据
          ...(inviter_code && { page_data: { message: inviter_code } }), // 页面数据
          session_id: Cookies.get('_ga'), // 会话ID(UserID后4位_时间戳)，如: f22a_1583251646199
          source: source, // 来源，如: H5(来自H5) App(来自App) Web(来自Web端),
          user_type: 10000, // 用户类型，0-未定义 1-没有任何产品 2-被分享用户 3-owner用户 4-owner+被分享用户
        },
      ],
    }
    return fetcher({
      locale,
      url: '/api/multipass/security-log/v1/app/logging/upload_commerce_events',
      method: 'POST',
      body: postdata,
      headers: { ...headers },
    })
  },
  getUsersProfile(locale, headers) {
    return fetcher({
      action: 'user',
      needRecaptcha: true,
      locale,
      url: '/api/multipass/account/users/profile',
      method: 'GET',
      headers: { ...headers },
    })
  },
}

export default referralApi
